import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import {Link} from "@reach/router";
import ModalContactSales from "../components/modals/modal-contact-sales";
import {graphql, navigate} from "gatsby";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import {customElements, documentReady, getCookie, setCookie} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const getBrowserLanguage = () => {
    if (typeof navigator === `undefined`) {
        return 'en';
    }

    const lang = navigator && navigator.language && navigator.language.split('-')[0];

    if (!lang) return 'en';

    if (!['es', 'en'].includes(lang)) {
        return 'en';
    }

    return lang;
}

const IndexPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const langSlug = lang === 'es' ? '/' + lang : '';
    const strapiHome = data.allStrapiHome.edges[0].node;
    const solutions = strapiHome.solutions_items;
    const services = strapiHome.services_items;
    const home = data.home.frontmatter[pageContext.locale.path.default];
    const company = pageContext.locale.path.default === 'es' ? 'Grandes compañías ya confían en nosotros' : 'Big companies already trust us';

    documentReady(() => {
        const browserLang = getBrowserLanguage();
        let languageChecked = getCookie("language-checked");

        if (languageChecked == null || languageChecked === '') {
            languageChecked = browserLang;
            setCookie("language-checked", browserLang, new Date("01-01-2100"));
        }

        console.log('language-checked', languageChecked, browserLang);

        if (typeof window !== 'undefined' && lang !== languageChecked) {
            const slug = languageChecked === 'en' ? '/' : `/${languageChecked}`;

            setTimeout(() => navigate(slug));
        }
    });

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang}
                 title={strapiHome.seo.title}
                 description={strapiHome.seo.meta_description}/>

            {/** WELCOME
             ================================================== */}
            <header id="section-home" className="header-lines">
                <div className="container h-100" data-aos="">
                    <div className="d-flex h-100 text-left align-items-center">
                        <div className="w-100 text-white mt-6">
                            <h1 className="display-2 text-white font-weight-black spacing mt-3">
                                {home.title_one}
                                <span className="display-2-sub font-weight-black spacing"> <a href="#tech-stats"
                                                                                              className="color-tecalis smooth-scroll">{home.title_link}</a> {home.title_two}</span>
                            </h1>
                            <span className="display-2-mob font-weight-black spacing">
                                <a href="#tech-stats" className="color-tecalis smooth-scroll">{home.title_link}</a> {home.title_two}
                            </span>
                            <br/>
                            <div className="logowall">
                                <p align="center" className="spacing text-wall">{company}</p>
                                <div align="center" className="logo-wall">
                                    <img src={require("../logos/telefonica-white.png").default} alt="Telefonica"/>
                                    <img src={require("../logos/prosegur-white.png").default} alt="Prosegur"/>
                                    <img src={require("../logos/claro-white.png").default} alt="Claro"/>
                                    <img src={require("../logos/vodafone-white.png").default} alt="Vodafone"/>
                                    <img src={require("../logos/enel-white.png").default} alt="Enel"/>
                                    <img src={require("../logos/valeo-white.png").default} alt="Valeo"/>
                                    <img src={require("../logos/orange-white.png").default} alt="Orange"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/** STATS
             ================================================== */}
            <section className="position-relative py-8 py-md-11 bg-grey" id="tech-stats">
                {/** Content */}
                <div className="container">
                    {/** / .row */}
                    <div className="row align-items-center">

                        <div className="col-12 col-md-6 col-lg-5 mb-5" data-aos="">
                            {/** Preheading */}
                            <h6 className="text-uppercase text-primary spacing">
                                {strapiHome.transformation_subtitle}
                            </h6>

                            {/** Heading */}
                            <h2 className="display-3 mb-0 mb-md-8 font-weight-bold spacing">
                                {strapiHome.transformation_title}
                            </h2>
                        </div>

                        <div className="col-12 col-md-12 col-lg-12" data-aos="fade-up">
                            <hr className="black-line"/>

                            {strapiHome.transformations.map((transformation, i) => {
                                return <div key={i}>
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md mt-4 mb-4">
                                            <h3 className="mb-0 display-2 font-weight-black spacing">
                                                <span data-toggle="countup" data-from="0" data-to={transformation.amount} data-aos data-aos-id="countup:in">0</span>
                                                {transformation.symbol}
                                            </h3>
                                        </div>

                                        <div className="col-12 col-md-3 mt-4 mb-4">
                                            <p className="font-size-lg text-muted mb-5 mb-md-0 spacing">
                                                {transformation.description}
                                            </p>
                                        </div>
                                    </div>

                                    <hr className="black-line"/>
                                </div>
                            })}
                        </div>

                    </div>
                    {/** / .row */}

                </div>
                {/** / .container */}

            </section>

            <section className="pt-10 pb-11 bg-white border-botto">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center">
                            <h2 align="center" className="display-4 font-weight-bold spacing mt-3 mb-8" data-aos="fade-right">
                                <ReactMarkdown children={strapiHome.opinions_title} rehypePlugins={[rehypeRaw]}/>
                            </h2>
                            <br/><br/>
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        {data.allStrapiOpinion.edges.map((opinion, i) => {
                            return (
                                <>
                                    <div className="col-12 col-xl mb-5" data-aos="fade-right" key={i}>
                                        <blockquote className="blockquote mb-8 mb-md-0">
                                            <img className="mb-6" src={opinion.node.company_logo.localFile.publicURL} height="48" alt="Logo"/>
                                            <br/>

                                            <StaticImage className="mb-2" src={"../img/fivestars.png"} width={90} alt="rating"/>

                                            <p className="font-size-lg text-black-80 font-weight-medium spacing mb-5 mb-md-7 testimonials-home">
                                                "{opinion.node.message}"
                                            </p>

                                            <div className="media">
                                                <img className="rounded-circle mr-3" src={opinion.node.photo.localFile.publicURL} alt={opinion.node.name} width="48"/>

                                                <div className="media-body">
                                                    <p className="font-size-lg spacing font-weight-bold mb-0">
                                                        <b>{opinion.node.name}</b>
                                                    </p>
                                                    <p className="font-size-sm spacing text-muted mb-0 why--op">
                                                        {opinion.node.job_position}
                                                    </p>
                                                </div>
                                            </div>
                                        </blockquote>
                                    </div>

                                    {i !== (data.allStrapiOpinion.edges.length - 1) && <div className="col-1"/>}
                                    {i !== (data.allStrapiOpinion.edges.length - 1) && <div className="col-1 border-left"/>}
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>

            {/**
             ================================================== */}
            <section className="mb-10 bg-white">
                <div className="container">
                    <div className="row mt-12">
                        <div className="col-xl-6 mb-5" data-aos="fade-down" data-aos-delay="0">
                            <h2 align="left" className="display-4 font-weight-bold spacing">
                                <ReactMarkdown children={strapiHome.solutions_title} rehypePlugins={[rehypeRaw]}/>
                            </h2>

                            <br/>

                            <h3 align="left" className="font-size-lg text-black-80 mb-6 font-weight-medium spacing">
                                {strapiHome.solutions_description}
                            </h3>

                            <br/>

                            <h3 className="font-weight-black spacing text-tecalis">
                                {strapiHome.solutions_subtitle}
                            </h3>

                            <hr className="black-line-nav"/>

                            {solutions.map((solution, i) => {
                                return <div className="media mb-3" key={i}>
                                    <GatsbyImage alt={solution.name} image={getImage(solution.icon.localFile)} className="mr-3 img-fluid border-icon-home"/>

                                    <div className="media-body stack-why">
                                        <h5 className="mt-0 prod-nav spacing">
                                            <Link to={solution.url} className="font-weight-black">
                                                {solution.name}
                                                <div className="badge badge-rounded-circle badge-success-soft ml-1">
                                                    <i className="fe fe-arrow-right color-teals"/>
                                                </div>
                                            </Link>
                                        </h5>

                                        <ReactMarkdown children={solution.descrption} rehypePlugins={[rehypeRaw]}
                                                       components={customElements('font-size-sm font-weight-medium why--op spacing')}/>
                                    </div>
                                </div>
                            })}
                        </div>

                        <div className="col-xl-1" data-aos="fade-left"/>

                        <div className="col-xl-5" data-aos="fade-left">
                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: 'relative', top: '43px', zIndex: '0'}}>
                                <StaticImage src={"../img/ic_decoration.svg"} alt="Decoration"/>
                            </div>


                            {/** Image grid */}
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0 position-relative">

                                {/** Image */}
                                <StaticImage className="img-fluid rounded shadow align-self-start" data-jarallax-element="-20"
                                             src={"../images/home3.jpg"} alt="..."
                                             style={{"minHeight": "1px", "maxWidth": "50%", "marginLeft": "40%"}}/>

                                {/** Image */}
                                <StaticImage className="img-fluid rounded shadow align-self-start"
                                             data-jarallax-element="-50"
                                             src={"../images/home2.jpg"} alt="..."
                                             style={{"minHeight": "1px", "maxWidth": "50%", "marginTop": "-35%"}}/>

                                {/** Image */}
                                <StaticImage className=" img-fluid rounded shadow align-self-center"
                                             data-jarallax-element="-80" src={"../images/home1.jpg"} alt="..."
                                             style={{"minHeight": "1px", "maxWidth": "50%", "marginTop": "-20%"}}/>

                            </div>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            {/** SERVICIOs
             ================================================== */}
            {lang === 'es' && <section className="py-9 py-md-11 bg-white border-top">
                <div className="container">
                    <div className="row align-items-stretch">
                        <div className="col-xl-6 mb-5" data-aos="fade-down" data-aos-delay="0">
                            <h2 align="left" className="display-4 font-weight-bold spacing mt-2">
                                <ReactMarkdown children={strapiHome.services_title} rehypePlugins={[rehypeRaw]}/>
                            </h2>

                            <br/>

                            <h3 align="left" className="font-size-lg text-black-80 mb-6 font-weight-medium spacing">
                                {strapiHome.services_description}
                            </h3>
                        </div>

                        <div className="col-xl-1" data-aos="fade-left"/>

                        <div className="col-xl-5" data-aos="fade-left">
                            <h3 className="font-weight-black spacing text-tecalis mb-6">
                                {strapiHome.services_subtitle}
                            </h3>

                            <div className="row">
                                {services.map((service, i) => {
                                    return <div className="col-xl-12" key={i}>
                                        <Link to={service.url}>
                                            <div className="op-btn2">
                                                <div className="media">
                                                    <GatsbyImage alt={service.name} image={getImage(service.icon.localFile)} className="mr-3 ml-5 img-fluid border-icon-home"
                                                                 style={{maxWidth: '60px', position: 'relative', top: '-3px'}}/>
                                                    <div align="left" className="media-body ml-3 mr-3">
                                                        <ReactMarkdown children={service.descrption} rehypePlugins={[rehypeRaw]}
                                                                       components={customElements('font-size-sm spacing')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}

            <section className="pt-11 pt-md-12 pb-11"
                     style={{background: 'url(' + require('../img/bg-cta.webp').default + ')', backgroundSize: 'cover', position: 'relative', zIndex: '9'}}>
                <div className="container pb-6 pb-md-8">
                    <div className="row">
                        <div align="center" className="col-xl-12">
                            <h2 className="font-weight-bold mb-1 display-4 spacing text-white">
                                {home.next_step}
                            </h2>
                            <br/><br/><br/>
                        </div>

                        <div className="col-xl-4">
                            <div className="card hvr-float mb-3">
                                <div className="card-body">
                                    <div align="center"/>

                                    <h2 className="font-weight-bold text-black-80 spacing mt-3 mb-4">
                                        {home.signature_title} <b>Tecalis Signature</b>
                                    </h2>

                                    <p>
                                        <Link to={langSlug + '/signature'} className="btn btn-primary btn-sm1 mr-2 spacing font-weight-bold mt-3" style={{width: '100%'}}>
                                            {home.signature_button}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card hvr-float mb-3">
                                <div className="card-body">
                                    <div align="center"/>

                                    <h2 className="font-weight-bold text-black-80 spacing mt-3 mb-4">
                                        {home.identity_title} <b>Tecalis Identity</b>
                                    </h2>

                                    <p>
                                        <Link to={langSlug + '/identity'} className="btn btn-primary btn-sm1 mr-2 spacing font-weight-bold mt-3" style={{width: '100%'}}>
                                            {home.identity_button}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card hvr-float mb-3">
                                <div className="card-body">
                                    <div align="center"/>

                                    <h2 className="font-weight-bold text-black-80 spacing mt-3 mb-4">
                                        {home.more_title}
                                    </h2>

                                    <p>
                                        <a href="#" data-toggle="modal" data-target="#modal-contact-sales" className="btn-primary-outline mr-2 spacing font-weight-bold mt-3"
                                           style={{width: '100%'}}>
                                            {home.more_button}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/** LATEST
             ================================================== */
            }
            {data.allStrapiPost.length > 0 && <section className="py-11 py-md-11">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {home.latest_news}
                                <br/><br/>
                            </h2>

                        </div>
                    </div>
                    {/** / .row */}
                    <div className="row">

                        {data.allStrapiPost.edges.map((document, i) => {

                            return (<div className="col-12 col-md-4 text-center border-right border-gray-300" key={i}>

                                {/** Heading */}
                                <div className="mb-5 mb-md-0">
                                    <div align="left" className="h3 text-tecalis spacing font-weight-bold">
                                        <Link to={document.node.fields.slug}>{document.node.resume} <i
                                            className="fe fe-arrow-right ml-1"/></Link>
                                    </div>
                                </div>

                            </div>)
                        })}

                    </div>
                    {/** / .row */}

                </div>
            </section>}

            {/** Partners
             ================================================== */
            }
            {/*<Partners/>*/}

            <ModalContactSales pageContext={pageContext}/>

        </Layout>
    )

}

export default IndexPage

export const indexPageQuery = graphql
    `query($lang: String)
    {
        allStrapiHome (
            filter: { locale: { eq: $lang } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                    }
                    hero_text,
                    transformation_title,
                    transfomation_subtitle,
                    transformations {
                        id,
                        amount,
                        symbol,
                        description
                    },
                    opinions_title,
                    solutions_title,
                    solutions_description,
                    solutions_subtitle,
                    solutions_items {
                        order
                        name
                        descrption
                        url
                        icon {
                            name
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 60
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    },
                    services_title,
                    services_description,
                    services_subtitle,
                    services_items {
                        order
                        name
                        descrption
                        url
                        icon {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 60
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
        allStrapiOpinion(
            filter: { locale: { eq: $lang }, in_home: {eq: true} }
            sort: { fields: [id], order: [DESC] }
            limit: 3
            skip: 0
        ){
            edges {
                node {
                    id
                    name
                    job_position
                    message
                    photo {
                        name
                        localFile {
                            publicURL
                        }
                    }
                    company_logo {
                        name
                        localFile {
                            publicURL
                        }
                    }
                    locale
                }
            }
        }
        
        allStrapiPost(
            sort : {
            fields: [publish_date], order : [DESC]
            }
            limit: 3
            skip: 0
            filter: {
                locale: { eq: $lang },
                add_to_index: {
                    eq: true
                }
            }
        )
        {
            edges
            {
                node
                {
                    id
                    title
                    description
                    resume
                    fields
                    {
                        slug
                    }
                }
            }
        }
        home: markdownRemark( frontmatter: { name: { eq: "home" } } )
        {
            frontmatter {
                en {
                    title_one
                    title_link
                    title_two
                    next_step
                    signature_title
                    signature_button
                    identity_title
                    identity_button
                    more_title
                    more_button
                    latest_news
                }
                es {
                    title_one
                    title_link
                    title_two
                    next_step
                    signature_title
                    signature_button
                    identity_title
                    identity_button
                    more_title
                    more_button
                    latest_news
                }
            }
        }
        cookies: markdownRemark(
            frontmatter : {
                lang: { eq: $lang }
                name: { eq: "cookies" }
            }
        ) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;
